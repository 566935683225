import React from 'react';
import { client } from '../../api/apolloClient';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';
const AccountPage = React.lazy(() => import('@izi-logistics/common/lib/pages/AccountPage'));

const Account = (props) => (
  <PreloadingComponent>
    <AccountPage {...props} config={{ client }} />
  </PreloadingComponent>
);
export default Account;
